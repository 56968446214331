<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="16">
          <el-form :inline="true" :model="formData">
            <el-form-item :label="$t('competitionDevice.searchDevice')">
              <el-input v-model="formData.name" :placeholder="$t('competitionDevice.msg.inputImeiPrompt')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchImei">{{$t('competition.btns.search')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form :inline="true" :model="formData">
            <el-form-item :label="$t('competitionDevice.addDevice')">
              <el-input ref="imeiInput" v-model="formData.imei" @input="onInputImei" :placeholder="$t('competitionDevice.msg.inputImeiPrompt')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onAddImei">{{$t('pigeonHouse.btns.add')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table ref="filterTable" :data="showDataList" stripe :border="true" style="width: 100%">
        <el-table-column fixed prop="index" :label="$t('common.sn')" width="80"></el-table-column>
        <el-table-column fixed prop="deviceImei" :label="$t('pigeonHouse.imei')" width="120"></el-table-column>
        <el-table-column fixed="right" :label="$t('common.operation')">
          <template slot-scope="scope">
            <el-button type="danger" @click="clickDeleteButton(scope.row)">{{$t('common.delete')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryRaceDeviceList, updateRaceDevice} from '@/api/requestUtils'
export default {
  data () {
    return {
      formData: {
        name: '',
        imei: ''
      },
      dataList: [],
      showDataList: [],
      raceId: 0,
      pagination: {
        pageSize: 10,
        currentPage: 1,
        totalData: 0
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
  },
  mounted () {
    const raceId = sessionStorage.getItem('raceId')

    console.log('raceId', raceId)

    if (raceId) {
      this.raceId = raceId
      this.onQueryRaceDeviceList()
    }
    this.$nextTick(() => {
        this.$refs.imeiInput.focus()
    })
  },
  methods: {
    onSearchImei () {
      if (this.formData.name && this.formData.name.trim()) {
        this.showDataList = []
        this.dataList.forEach(item => {
          if (item.deviceImei.includes(this.formData.name)) {
            this.showDataList.push(item)
          }
        })
      } else {
        this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
      }
      // this.$message.error('找不到该比赛,请确认比赛名称是否正确')
    },
    handleSizeChange (pageSize) { // 分页:单页最大数量变化时的处理
      this.pagination.currentPage = 1
      this.pagination.pageSize = pageSize
      this.showDataList = this.dataList.slice(0, pageSize)
    },
    handleCurrentChange (currentPage) { // 分页:当前页数变化时的处理
      this.pagination.currentPage = currentPage
      this.showDataList = this.dataList.slice((currentPage - 1) * this.pagination.pageSize, currentPage * this.pagination.pageSize)
    },
    onInputImei () {
      let imei = this.formData.imei
      if (imei && imei.trim() && (imei.substring(imei.length - 1, imei.length) === ' ' || imei.trim().length == 15)) {
          this.onUpateRaceDevice(this.raceId, imei.trim())
          this.formData.imei = ''
          this.$nextTick(() => {
            this.$refs.imeiInput.focus()
          })
      }
    },
    onAddImei () {
      if (this.formData.imei && this.formData.imei.trim()) {
          this.onUpateRaceDevice(this.raceId, this.formData.imei.trim())
      }
    },
    clickDeleteButton (data) {
      this.showConfirmDialog(this.$t('common.notice'), this.$t('competitionDevice.msg.deleteTip'), () => {
        this.onUpateRaceDevice(null, data.deviceImei)
      })
    },
    showConfirmDialog (title, content, confirmCallback) {
      this.$confirm(content, title, {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warnning'
      }).then(confirmCallback).catch(() => {})
    },
    onQueryRaceDeviceList () {
      queryRaceDeviceList(this.raceId)
        .then((res) => {
          if (res.data.code === 0 && res.data.data) {
            this.dataList = []
            this.showDataList = []
            if (res.data.data[0]) {
              res.data.data[0].forEach((item) => {
                item.index = this.dataList.length + 1
                this.dataList.push(item)
              })
            }
            this.pagination.totalData = this.dataList.length
            this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
           if(!this.showDataList.length) {
             this.showDataList = this.dataList.slice(0, this.pagination.pageSize)
           }
          }
        })
        .catch((e) => {})
    },
    onUpateRaceDevice (raceId, imei) {
      updateRaceDevice(raceId, imei).then((res) => {
          if (res.data.code === 0) {
            this.$message.success(this.$t("common.updateSuccess"))
            if(raceId) {
                this.formData.imei = ''
                this.dataList.push({
                    index: this.dataList.length + 1,
                    deviceImei: imei
                })
            } else {
              for (let index = 0; index < this.dataList.length; index++) {
                let item = this.dataList[index];
                if (imei === item.deviceImei) {
                  this.dataList.splice(index, 1);
                  break;
                }
              }
            }
            this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
          } else {
            this.$message.error(this.$t("common.updateFailed"))
          }
        })
        .catch((e) => {})
    },
  }
}
</script>
<style lang="less" scoped>
</style>
